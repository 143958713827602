import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, collection } from "firebase/firestore/lite";

// const firebaseApp = initializeApp({
//   apiKey: "AIzaSyB5MRywN6C0wyCbMhxnwBbwcLu7BGWGZF0",
//   authDomain: "contact-form-151d2.firebaseapp.com",
//   projectId: "contact-form-151d2",
//   storageBucket: "contact-form-151d2.appspot.com",
//   messagingSenderId: "554437407611",
//   appId: "1:554437407611:web:2fe05021eb3c2b7761c3de",
// });

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBigWmTPZjduNgGJPbKDYgRiNBFssRpJuM",
  authDomain: "portfolio-website-e960d.firebaseapp.com",
  projectId: "portfolio-website-e960d",
  storageBucket: "portfolio-website-e960d.appspot.com",
  messagingSenderId: "470039850579",
  appId: "1:470039850579:web:9d41fb5e28a28e6a82a0a6",
  measurementId: "G-LSKS573KTW",
});

// // init firestore service
const fs = getFirestore(firebaseApp);

export { fs, addDoc, collection };
