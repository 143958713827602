<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ `${content}` }}</template>
    </metainfo>

    <div class="contact">
      <main class="contact__main-content">
        <form class="contact__form" @submit.prevent="onSubmit">
          <label for="name" class="visuallyhidden">Name</label>
          <input class="input" id="Name" placeholder="Name" v-model="name" />

          <label for="email" class="visuallyhidden">Email Address</label>
          <input
            class="input"
            id="Email"
            placeholder="Email Address"
            v-model="email"
          />

          <label for="message" class="visuallyhidden">Message</label>
          <textarea
            class="textarea"
            id="Review"
            placeholder="Message"
            v-model="message"
          ></textarea>

          <input
            class="contact__form__btn"
            type="submit"
            value="Send Message"
          />
        </form>
      </main>

      <div class="whitespace"></div>
    </div>
  </div>
</template>

<script>
import { fs, addDoc, collection } from "../index";
import { useMeta } from "vue-meta";

export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  // 6:30
  methods: {
    // I NEED TO CHECK THIS VIDEO TO IMPLEMENT ERROR HANDLING - https://www.youtube.com/watch?v=z-pyxOx3i1s
    onSubmit() {
      if (this.name === "" || this.email === "" || this.message === "") {
        alert("Form is incomplete. Please fill out every field");
        return;
      }

      let newContact = {
        to: "lccdehsen@gmail.com",
        message: {
          name: this.name,
          email: this.email,
          html: `name: ${this.name}
          message: ${this.message}
          email ${this.email}`,
        },
      };

      const firestoreDB = collection(fs, "contacts");
      addDoc(firestoreDB, newContact)
        .then(() => {
          this.name = "";
          this.email = "";
          this.message = "";
          this.$router.push({ path: "success" });
        })
        .catch((error) => {
          this.$router.push({ path: "error" });
          this.$store.state.error = error;
          console.log("ERROR:" + this.$store.state.error);
        });
    },
  },
  beforeMount() {
    const headshotContainer = document.querySelector(".headshot__container");
    // const headshotImage = document.querySelector(".item-image");
    const hireButton = document.querySelector(".item-btn");
    // const myName = document.querySelector(".item-name");
    // headshotImage.classList.remove(".headshot-image");
    // hireButton.classList.remove(".hire-btn");
    // myName.classList.remove(".my-name");
    headshotContainer.classList.add("headshot__container-contact");

    if (headshotContainer.classList.contains("hideHeadshot")) {
      console.log(headshotContainer, "working");
    }
    hireButton.classList.add("hire-btn-contact");
    // headshotContainer.classList.add("hideHeadshot");
    // headshotImage.classList.add("hideHeadshot");
    // myName.classList.add("hideHeadshot");
  },
  beforeUnmount() {
    const headshotContainer = document.querySelector(".headshot__container");
    headshotContainer.classList.remove("headshot__container-contact");
    const hireButton = document.querySelector(".item-btn");
    hireButton.classList.remove("hire-btn-contact");
  },

  // mounted() {
  //   const headshotContainer = document.querySelector(".headshot__container");
  //   const headshotImage = document.querySelector(".headshot-image");
  //   const hireButton = document.querySelector(".hire-btn");
  //   const myName = document.querySelector(".my-name");
  //   hireButton.classList.add("hideHeadshot");
  //   headshotContainer.classList.add("hideHeadshot");
  //   headshotImage.classList.add("hideHeadshot");
  //   myName.classList.add("hideHeadshot");
  // },
  // beforeUnmount() {
  //   const headshotContainer = document.querySelector(".headshot__container");
  //   const headshotImage = document.querySelector(".headshot-image");
  //   const hireButton = document.querySelector(".hire-btn");
  //   const myName = document.querySelector(".my-name");
  //   headshotContainer.classList.remove("hideHeadshot");
  //   headshotImage.classList.remove("hideHeadshot");
  //   hireButton.classList.remove("hideHeadshot");
  //   myName.classList.remove("hideHeadshot");
  // },
  setup() {
    useMeta({
      title: "Dehsen Ragavulu | Contact Me",
      htmlAttrs: { lang: "en-ZA", amp: true },
      description: [
        {
          name: "description",
          content:
            "Dehsen Ragavulu - UI/UX Designer & Web Developer. Feel free to contact me if you are in need of a Javascript/Vue developer",
        },
      ],
    });
  },

  name: "Contact",
};
</script>

<style lang="scss" scoped>
.contact {
  // background: linear-gradient(
  //   115deg,
  //   rgb(107, 63, 136),
  //   rgb(48, 108, 133) 40%,
  //   rgb(0, 25, 89) 70%,
  //   rgb(48, 152, 156) 85%,
  //   rgb(107, 63, 136)
  // );
  // height: 78.5vh;
  // animation: change-hue-max 10000ms infinite linear;

  &__form {
    display: flex;
    flex-direction: column;
    width: 27%;
    background-color: #21465ae8;
    padding: 3rem;
    border-radius: 5px;
    justify-content: center;
    margin: 50px 0 50px 40%;
    // margin-left: 40%
    /* position: absolute; */
    /* left: 50%; */
    /* top: 150px; */
    /* transform: translate(-50%, 10px); */
    padding-left: 50p;
    /* margin-top: -30px; */

    // display: flex;
    // flex-direction: column;
    // width: 27%;
    // background-color: #21465ae8;
    // padding: 3rem;
    // border-radius: 5px;
    // justify-content: center;
    // position: absolute;
    // left: 50%;
    // top: 150px;
    // transform: translate(-50%, 10px);

    @media screen and (max-width: 1384px) {
      width: 450px;
    }

    @media screen and (max-width: 833px) {
      margin: 150px auto 50px auto;
    }
    @media screen and (max-width: 777px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 515px) {
      width: 90vw;
      top: 80px;
    }

    .input,
    .textarea {
      caret-color: #fff;
      background: transparent;
      border: none;
      border-bottom: 1px solid #fff;
      color: #fff;
      font-family: "Josefin Sans", sans-serif;
      font-weight: 600;
      font-size: 2.2rem;

      &::placeholder {
        color: #fff;
        font-family: "Josefin Sans", sans-serif;
        font-weight: 600;
        font-size: 2.2rem;
      }
    }

    #Email {
      padding-top: 7rem;
    }
    .textarea {
      padding-top: 4rem;
      height: 28rem;
    }

    .input {
      padding: 1.25rem 1.25rem 1.25rem 0;
    }

    &__btn {
      cursor: pointer;
      width: 100%;
      margin-top: 5rem;
      background: #fff;
      // color: rgb(107, 63, 136);
      font-size: 2.2rem;
      border-radius: 4px;
      padding: 1.1rem 0.9rem;
      font-family: "Josefin Sans", sans-serif;
      border: none;

      &:hover,
      &:active {
        // color: rgb(107, 63, 136);
      }
    }
  }

  &__section-heading,
  &__intro-text {
    text-align: center;
  }
  &__section-heading {
    font-size: 5rem;
    margin-top: 6rem;
    // margin-bottom: 5rem;
    // margin: 0 auto;
    text-align: center;
  }

  &__intro-text {
    font-family: "Raleway", sans-serif;
    // width: 60%;
    // margin-left: auto;
    // margin-top: 4.4rem;
    margin-bottom: 3.6rem;
  }

  .hr {
    height: 2.5px;
    border: 0;
    width: 100%;
    background-image: linear-gradient(
      to right,
      rgb(48, 152, 156),
      rgb(107, 63, 136) 15% 25%,
      rgb(48, 108, 133) 40%,
      rgb(0, 25, 89) 75%,
      rgb(48, 152, 156)
    );
    width: 100%;
  }

  /******  Headshot image  ****************************************/
  // &__main-content {
  //   display: flex;
  //   justify-content: center;
  //   margin-top: 5rem;
  //   margin-bottom: 10rem;
  // }

  &__secondary-headings {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // &__section-heading {
  //   font-size: 5rem;
  //   margin-top: 4rem;
  //   margin-bottom: 5rem;
  // }

  &__section-heading-secondary {
    font-size: 3rem;
    margin-top: 3.5rem;
  }

  &__main-content-text {
    margin-left: 1rem;
  }

  .whitespace {
    height: 72px;
  }
}

.headshot__container {
  animation: slide-up-enter 0.3s ease-in;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
</style>
