/* eslint-disable prettier/prettier */ /* eslint-disable prettier/prettier */
<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ `${content}` }}</template>
    </metainfo>
    <div class="about">
      <!-- <img
        v-bind:src="require('@/assets/images/playing-guitar-inverted.jpg')"
        alt="playing guitar inverted color"
        class="about__headerImage"
      /> -->

      <div class="content-container about-content-container">
        <p class="about__intro-text">
          Consistently learning new design & development skills. Send me a
          message. Let's discuss your project
        </p>

        <hr class="hr about-hr" />

        <main class="about__main-content">
          <div class="about__design">
            <div class="about__secondary-headings">
              <h2 class="about__section-heading">UI & UX Designer</h2>
              <svg
                id="design-svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                version="1.1"
                width="114"
                height="114"
                x="0"
                y="0"
                viewBox="0 0 512 512"
                style="enable-background: new 0 0 512 512"
                xml:space="preserve"
                class=""
              >
                <g>
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    id="a"
                    gradientUnits="userSpaceOnUse"
                    x1="9.335"
                    x2="539.944"
                    y1="482.665"
                    y2="-47.944"
                    data-name="New Gradient Swatch 1"
                  >
                    <stop stop-opacity="1" stop-color="#6b3f88" offset="0" />
                    <stop
                      stop-opacity="1"
                      stop-color="#306c85"
                      offset="0.518"
                    />
                    <stop stop-opacity="1" stop-color="#001959" offset="1" />
                  </linearGradient>
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="b"
                    x1="-182.665"
                    x2="347.944"
                    xlink:href="#a"
                    y1="290.665"
                    y2="-239.944"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="c"
                    x1="-166.665"
                    x2="363.944"
                    xlink:href="#a"
                    y1="306.665"
                    y2="-223.944"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="d"
                    x1="-150.665"
                    x2="379.944"
                    xlink:href="#a"
                    y1="322.665"
                    y2="-207.944"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="e"
                    x1="-126.665"
                    x2="403.944"
                    xlink:href="#a"
                    y1="346.665"
                    y2="-183.944"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="f"
                    x1="-110.665"
                    x2="419.944"
                    xlink:href="#a"
                    y1="362.665"
                    y2="-167.944"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="g"
                    x1="-94.665"
                    x2="435.944"
                    xlink:href="#a"
                    y1="378.665"
                    y2="-151.944"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="h"
                    x1="3.335"
                    x2="533.944"
                    xlink:href="#a"
                    y1="476.665"
                    y2="-53.944"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="i"
                    x1="-24.664"
                    x2="505.944"
                    xlink:href="#a"
                    y1="448.664"
                    y2="-81.944"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="j"
                    x1="63.335"
                    x2="593.944"
                    xlink:href="#a"
                    y1="536.665"
                    y2="6.056"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="k"
                    x1="75.335"
                    x2="605.944"
                    xlink:href="#a"
                    y1="548.665"
                    y2="18.056"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="l"
                    x1="63.085"
                    x2="593.694"
                    xlink:href="#a"
                    y1="536.415"
                    y2="5.806"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="m"
                    x1="99.335"
                    x2="629.944"
                    xlink:href="#a"
                    y1="572.665"
                    y2="42.056"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="n"
                    x1="111.335"
                    x2="641.944"
                    xlink:href="#a"
                    y1="584.665"
                    y2="54.056"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="o"
                    x1="123.335"
                    x2="653.944"
                    xlink:href="#a"
                    y1="596.665"
                    y2="66.056"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="p"
                    x1="119.335"
                    x2="649.944"
                    xlink:href="#a"
                    y1="592.665"
                    y2="62.056"
                  />
                  <g xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="m476 32h-440a28.031 28.031 0 0 0 -28 28v344a28.031 28.031 0 0 0 28 28h4v12a28.031 28.031 0 0 0 28 28h376a28.031 28.031 0 0 0 28-28v-12h4a28.031 28.031 0 0 0 28-28v-344a28.031 28.031 0 0 0 -28-28zm-20 412a12.01 12.01 0 0 1 -12 12h-376a12.01 12.01 0 0 1 -12-12v-268h400zm0-284h-400v-20a12.01 12.01 0 0 1 12-12h376a12.01 12.01 0 0 1 12 12zm32 244a12.01 12.01 0 0 1 -12 12h-4v-276a28.031 28.031 0 0 0 -28-28h-376a28.031 28.031 0 0 0 -28 28v276h-4a12.01 12.01 0 0 1 -12-12v-308h464zm0-324h-464v-20a12.01 12.01 0 0 1 12-12h440a12.01 12.01 0 0 1 12 12z"
                      fill="url(#a)"
                      data-original="url(#a)"
                      style=""
                      class=""
                    />
                    <path
                      d="m48 56h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z"
                      fill="url(#b)"
                      data-original="url(#b)"
                      style=""
                    />
                    <path
                      d="m80 56h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z"
                      fill="url(#c)"
                      data-original="url(#c)"
                      style=""
                    />
                    <path
                      d="m112 56h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z"
                      fill="url(#d)"
                      data-original="url(#d)"
                      style=""
                    />
                    <path
                      d="m80 136h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z"
                      fill="url(#e)"
                      data-original="url(#e)"
                      style=""
                    />
                    <path
                      d="m112 136h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z"
                      fill="url(#f)"
                      data-original="url(#f)"
                      style=""
                    />
                    <path
                      d="m144 136h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z"
                      fill="url(#g)"
                      data-original="url(#g)"
                      style=""
                      class=""
                    />
                    <path
                      d="m256 232h-176a8 8 0 0 0 -8 8v144a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8v-144a8 8 0 0 0 -8-8zm-156.69 144 28.69-28.69 28.69 28.69zm148.69 0h-68.69l-20-20 40.69-40.69 48 48zm0-35.31-42.34-42.35a8.015 8.015 0 0 0 -11.32 0l-46.34 46.35-14.34-14.35a8.015 8.015 0 0 0 -11.32 0l-34.34 34.35v-116.69h160z"
                      fill="url(#h)"
                      data-original="url(#h)"
                      style=""
                      class=""
                    />
                    <path
                      d="m128 320a24 24 0 1 0 -24-24 24.027 24.027 0 0 0 24 24zm0-32a8 8 0 1 1 -8 8 8.009 8.009 0 0 1 8-8z"
                      fill="url(#i)"
                      data-original="url(#i)"
                      style=""
                      class=""
                    />
                    <path
                      d="m432 232h-144a8 8 0 0 0 0 16h144a8 8 0 0 0 0-16z"
                      fill="url(#j)"
                      data-original="url(#j)"
                      style=""
                    />
                    <path
                      d="m432 256h-144a8 8 0 0 0 0 16h144a8 8 0 0 0 0-16z"
                      fill="url(#k)"
                      data-original="url(#k)"
                      style=""
                      class=""
                    />
                    <path
                      d="m288 296h47a8 8 0 0 0 0-16h-47a8 8 0 0 0 0 16z"
                      fill="url(#l)"
                      data-original="url(#l)"
                      style=""
                    />
                    <path
                      d="m432 304h-144a8 8 0 0 0 0 16h144a8 8 0 0 0 0-16z"
                      fill="url(#m)"
                      data-original="url(#m)"
                      style=""
                    />
                    <path
                      d="m432 328h-144a8 8 0 0 0 0 16h144a8 8 0 0 0 0-16z"
                      fill="url(#n)"
                      data-original="url(#n)"
                      style=""
                    />
                    <path
                      d="m432 352h-144a8 8 0 0 0 0 16h144a8 8 0 0 0 0-16z"
                      fill="url(#o)"
                      data-original="url(#o)"
                      style=""
                    />
                    <path
                      d="m368 376h-80a8 8 0 0 0 0 16h80a8 8 0 0 0 0-16z"
                      fill="url(#p)"
                      data-original="url(#p)"
                      style=""
                    />
                  </g>
                </g>
              </svg>
            </div>

            <div class="about__main-content-text">
              <p class="about__paragraph">
                I prefer clean design that is informed by function & usability.
                I also take a user-first, content-first, and mobile-first
                approach to design
              </p>

              <h3 class="about__section-heading-secondary">Process:</h3>
              <p class="about__paragraph">
                Steps 1-7 pertain to the creation of a Strategy & Information
                Architecture document. This document assists with creating
                content & designing interfaces which cater well to their
                intended users.
              </p>

              <!-- <transition-group
                tag="ol"
                name="slide-up"
                appear
                class="about__process-list"
              > -->
              <ol class="about__process-list">
                <li class="about__list-item about__list-item-design">
                  Business Concept
                </li>
                <li class="about__list-item about__list-item-design">
                  Target Audience
                </li>
                <li class="about__list-item about__list-item-design">
                  Persona creation
                </li>
                <li class="about__list-item about__list-item-design">
                  User needs
                </li>
                <li class="about__list-item about__list-item-design">
                  Client needs
                </li>
                <li class="about__list-item about__list-item-design">
                  Outline of scope
                </li>
                <li class="about__list-item about__list-item-design">
                  Site Map
                </li>
                <li class="about__list-item about__list-item-design">
                  Content creation
                </li>
                <li class="about__list-item about__list-item-design">
                  UI design
                </li>
              </ol>
              <!-- </transition-group> -->

              <p class="about__paragraph">
                I create the content before designing the UI. This helps
                usability because the design is informed by the actual content.
                I use a process of priority guides for each page's content.
                Content is created according to the priority that a user would
                require it in. Once all the content is created, I design the
                user interface with real content. This is also great for working
                in a team because it speeds up design & development without
                killing creativity.
              </p>

              <h3 class="about__section-heading-secondary">
                Tools that I use:
              </h3>
              <p class="about__paragraph">
                Adobe XD, PhotoShop, Illustrator, Figma, Balsamiq, Optimal
                Workshop, HotJar, Google Analytics
              </p>
            </div>
          </div>

          <hr class="about__divider" />

          <div class="about__development">
            <div class="about__secondary-headings">
              <h2 class="about__section-heading">Front-end Developer</h2>
              <svg
                id="development-svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                version="1.1"
                width="142"
                height="140"
                x="0"
                y="0"
                viewBox="0 0 512 512"
                style="enable-background: new 0 0 512 512"
                xml:space="preserve"
                class=""
              >
                <g>
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    id="a"
                    gradientUnits="userSpaceOnUse"
                    x1="25.013"
                    x2="521.517"
                    y1="506.987"
                    y2="10.483"
                    data-name="New Gradient Swatch 1"
                  >
                    <stop stop-opacity="1" stop-color="#6b3f88" offset="0" />
                    <stop
                      stop-opacity="1"
                      stop-color="#306c85"
                      offset="0.5173745173745173"
                    />
                    <stop stop-opacity="1" stop-color="#001959" offset="1" />
                  </linearGradient>
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="b"
                    x1="-8.987"
                    x2="487.517"
                    xlink:href="#a"
                    y1="472.987"
                    y2="-23.517"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="c"
                    x1="17.013"
                    x2="513.517"
                    xlink:href="#a"
                    y1="498.987"
                    y2="2.483"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="d"
                    x1="3.013"
                    x2="499.517"
                    xlink:href="#a"
                    y1="484.987"
                    y2="-11.517"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="e"
                    x1="7.013"
                    x2="503.597"
                    xlink:href="#a"
                    y1="488.988"
                    y2="-7.597"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="f"
                    x1="35.012"
                    x2="531.517"
                    xlink:href="#a"
                    y1="516.987"
                    y2="20.483"
                  />
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    id="g"
                    x1="-20.988"
                    x2="475.517"
                    xlink:href="#a"
                    y1="460.987"
                    y2="-35.517"
                  />
                  <g xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="m496 368h-32v-260a28.031 28.031 0 0 0 -28-28h-360a28.031 28.031 0 0 0 -28 28v260h-32a8 8 0 0 0 -8 8v28a28.031 28.031 0 0 0 28 28h440a28.031 28.031 0 0 0 28-28v-28a8 8 0 0 0 -8-8zm-432-260a12.01 12.01 0 0 1 12-12h360a12.01 12.01 0 0 1 12 12v260h-384zm224 276v8h-64v-8zm200 20a12.01 12.01 0 0 1 -12 12h-440a12.01 12.01 0 0 1 -12-12v-20h184v16a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-16h184z"
                      fill="url(#a)"
                      data-original="url(#a)"
                      style=""
                      class=""
                    />
                    <path
                      d="m88 392h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16z"
                      fill="url(#b)"
                      data-original="url(#b)"
                      style=""
                    />
                    <path
                      d="m120 392h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z"
                      fill="url(#c)"
                      data-original="url(#c)"
                      style=""
                    />
                    <path
                      d="m440 352v-240a8 8 0 0 0 -8-8h-352a8 8 0 0 0 -8 8v240a8 8 0 0 0 8 8h352a8 8 0 0 0 8-8zm-16-8h-336v-224h336z"
                      fill="url(#d)"
                      data-original="url(#d)"
                      style=""
                      class=""
                    />
                    <path
                      d="m220.031 302.946a8 8 0 0 0 10.915-2.977l64-112a8 8 0 1 0 -13.892-7.938l-64 112a8 8 0 0 0 2.977 10.915z"
                      fill="url(#e)"
                      data-original="url(#e)"
                      style=""
                    />
                    <path
                      d="m307.2 310.4a8 8 0 0 0 11.2-1.6l48-64a8 8 0 0 0 0-9.6l-48-64a8 8 0 0 0 -12.8 9.6l44.4 59.2-44.4 59.2a8 8 0 0 0 1.6 11.2z"
                      fill="url(#f)"
                      data-original="url(#f)"
                      style=""
                      class=""
                    />
                    <path
                      d="m193.6 308.8a8 8 0 1 0 12.8-9.6l-44.4-59.2 44.4-59.2a8 8 0 1 0 -12.8-9.6l-48 64a8 8 0 0 0 0 9.6z"
                      fill="url(#g)"
                      data-original="url(#g)"
                      style=""
                    />
                  </g>
                </g>
              </svg>
            </div>

            <div id="development" class="about__main-content-text">
              <p class="about__paragraph">
                I use Vue.js for most of my projects. I also build highly
                performant websites with just HTML, CSS, and JavaScript.
              </p>

              <h3 class="about__section-heading-secondary">Process:</h3>
              <p class="about__paragraph">
                I prefer test-driven development and use the following steps
                when building out an app:
              </p>

              <ol class="about__process-list">
                <li class="about__list-item about__list-item-development">
                  Build the “scaffolding”, and validate the HTML markup of the
                  web app.
                </li>
                <li class="about__list-item about__list-item-development">
                  Develop each component using semantic markup & test via the
                  HTML & CSS W3C validators.
                </li>
                <li class="about__list-item about__list-item-development">
                  Test each Vue.js component using Jest
                </li>
                <li class="about__list-item about__list-item-development">
                  Create all transitions & animations
                </li>
                <li class="about__list-item about__list-item-development">
                  Perform mobile tests on real android & ios devices
                </li>
                <li class="about__list-item about__list-item-development">
                  Perform cross browser tests using lambdatest
                </li>
                <li class="about__list-item about__list-item-development">
                  Perform Google lighthouse test on each page
                </li>
                <li class="about__list-item about__list-item-development">
                  Perform in-depth Accessibility test on each page using Axe &
                  Wave
                </li>
                <li class="about__list-item about__list-item-development">
                  Perform Advanced tests using webpagetest.org
                </li>
                <li class="about__list-item about__list-item-development">
                  Run build command and deploy to server
                </li>
              </ol>

              <h3 class="about__section-heading-secondary">
                Languages, frameworks etc. that I use:
              </h3>
              <p class="about__paragraph">
                HTML, CSS, JavaScript, Ajax(Axios), JSON, BEM SCSS, 7-1 SCSS,
                Node-Sass, Bootstrap 4, Tailwind, REST API’S, Vue.js 3, Vue
                Router, Vuex4, Vue CLI 3, Unit testing (Vue Test Utils & Jest),
                SVG/Vue Animations, GSAP, GraphQL, Git, GitHub, npm, web pack
              </p>
            </div>
          </div>
        </main>

        <div class="whitespace"></div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { useMeta } from "vue-meta";

export default {
  name: "About",
  components: {},

  mounted() {
    gsap.from(".about__list-item-design", {
      scrollTrigger: {
        trigger: ".about__list-item-design",
        start: "50% 100%",
        // end: "bottom 800px",
        // markers: true,
        // immediateRender: false,
        toggleActions: "play none none none",
      },
      duration: 1,
      opacity: 0,
      // scale: 0,
      y: 200,
      ease: "power1",
      stagger: 0.1,
    });
    gsap.from(".about__list-item-development", {
      scrollTrigger: {
        trigger: ".about__list-item-development",
        start: "50% 100%",
        // end: "bottom 800px",
        // markers: true,
        // immediateRender: false,
        toggleActions: "play none none none",
      },
      duration: 1,
      opacity: 0,
      // scale: 0,
      y: 200,
      ease: "power1",
      stagger: 0.1,
    });

    // Headshot transition
    const headshot = document.querySelector(".headshot__container");
    headshot.classList.remove("invisible");

    // background image
    // const bg = document.querySelector(".bg-img");
    // bg.classList.remove("home-bg-img");
    // bg.classList.add("about-bg-img");
  },
  beforeMount() {
    const headshotContainer = document.querySelector(".headshot__container");
    const headshotImage = document.querySelector(".headshot-image");
    const hireButton = document.querySelector(".hire-btn");
    const myName = document.querySelector(".my-name");
    if (headshotContainer.classList.contains("hideHeadshot")) {
      headshotContainer.classList.remove("hideHeadshot");
      headshotImage.classList.remove("hideHeadshot");
      hireButton.classList.remove("hideHeadshot");
      myName.classList.remove("hideHeadshot");
    } else {
      console.log(headshotContainer);
    }
  },
  beforeUnmount() {
    // const headshotContainer = document.querySelector(".headshot__container");
    // const headshotImage = document.querySelector(".headshot-image");
    // const hireButton = document.querySelector(".hire-btn");
    // const myName = document.querySelector(".my-name");
    // headshotContainer.classList.add("hideHeadshot");
    // headshotImage.classList.add("hideHeadshot");
    // hireButton.classList.add("hideHeadshot");
    // myName.classList.add("hideHeadshot");
  },

  setup() {
    useMeta({
      title: "Dehsen Ragavulu | About Me",
      htmlAttrs: { lang: "en-ZA", amp: true },
      description: [
        {
          name: "description",
          content:
            "Dehsen Ragavulu - UI/UX Designer & Web Developer. I design & build Web apps. I really enjoy working with Vue.js.",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.about {
  #design-svg {
    margin-bottom: 8rem;
    @media screen and (max-width: 790px) {
      margin-bottom: 3rem;
      margin-top: 4rem;
    }
    @media screen and (max-width: 465px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  #development-svg {
    margin-bottom: 5.4rem;
    @media screen and (max-width: 790px) {
      // margin: 0;
      margin-bottom: 0.4rem;
    }
    @media screen and (max-width: 465px) {
      margin-top: -1.5rem;
      margin-bottom: -1rem;
    }
  }
  #design-svg,
  #development-svg {
    @media screen and (max-width: 790px) {
      width: 100px;
    }
    @media screen and (max-width: 465px) {
      width: 89px;
    }
  }

  &__headerImage {
    max-height: 78.5vh;
    object-fit: cover;
    width: 100%;
    object-position: center 75%;
    // animation: change-hue 10000ms infinite linear;
  }

  &__intro-text {
    font-family: "Raleway", sans-serif;
    width: 60%;
    margin-left: auto;
    margin-top: 4.4rem;
    margin-bottom: 3.6rem;
    // margin-right: -12px;
  }
  .hr {
    height: 2.5px;
    border: 0;
    width: 100%;
    background-image: linear-gradient(
      to right,
      rgb(48, 152, 156),
      rgb(107, 63, 136) 15% 25%,
      rgb(48, 108, 133) 40%,
      rgb(0, 25, 89) 75%,
      rgb(48, 152, 156)
    );
    width: 100%;
  }

  .about-hr {
    margin-top: 14rem;
  }

  /******  DESIGN SECTION  ****************************************/
  &__main-content {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 10rem;

    & > * {
      flex: 1;
    }

    @media screen and (max-width: 790px) {
      flex-direction: column;
    }
  }

  // &__design {
  //   @media screen and (max-width: 790px) {
  //     order: 1;
  //   }
  // }

  &__secondary-headings {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__section-heading {
    font-size: 5rem;
    margin-top: 4rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 1238px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 790px) {
      margin: 0;
      // margin-bottom: 3rem;;
    }
    @media screen and (max-width: 317px) {
      font-size: 3rem;
      // margin-bottom: 3rem;;
    }
  }

  &__section-heading-secondary {
    font-size: 3rem;
    margin-top: 3.5rem;
    @media screen and (max-width: 317px) {
      font-size: 2.5rem;
      text-decoration: underline;
      // margin-bottom: 3rem;;
    }
  }

  &__main-content-text {
    margin-left: 1rem;
    @media screen and (max-width: 790px) {
      margin-left: 0;
    }
  }

  &__process-list {
    margin-left: 3rem;
    position: relative;
    margin-top: 3rem;
    margin-bottom: 3rem;
    list-style-position: inside;
    @media screen and (max-width: 1019px) {
      margin-left: 4rem;
    }
    @media screen and (max-width: 790px) {
      margin-top: 5rem;
    }
    @media screen and (max-width: 371px) {
      margin-left: 5rem;
    }
  }
  &__list-item {
    counter-increment: list;
    list-style-type: none;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 1.7rem;
    }

    &::marker {
    }
    &:before {
      color: #fff;
      content: counter(list) "";
      left: -32px;
      position: absolute;
      text-align: right;
      width: 26px;
      padding: 2px 10px 0px 5px;
      -webkit-border-radius: 20%;
      -moz-border-radius: 20%;
      border-radius: 20%;
      background: rgb(107, 63, 136);
    }
  }

  &__divider {
    margin: 0 6rem;
    height: 100;
    border: 0;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgb(107, 63, 136),
      rgb(48, 108, 133) 40%,
      rgb(0, 25, 89) 75%,
      rgb(48, 152, 156)
    );
    max-width: 5px;
    margin-top: 355px;

    @media screen and (max-width: 790px) {
      // display: none;
      height: 2.5px;
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  #development li {
    margin-top: 3.5rem;

    &:last-child:before {
      padding-right: 25px;
    }
  }

  #development ol {
    margin-bottom: 5.5rem;
  }

  .whitespace {
    height: 72px;
  }
}

.headshot__container {
  animation: slide-up-enter 0.3s ease-in;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}
</style>
