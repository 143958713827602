<template>
  <section class="work">
    <div
      v-for="project in projects.projectList"
      :key="project.index"
      class="work__card-container"
    >
      <div class="work__card-image-container">
        <a href="#" class="work__card-link" @click.prevent
          ><span class="work__card-link-text">view case study</span>
          <img
            v-bind:src="require('@/assets/images/card-images/' + project.img)"
            :alt="project.name"
            class="work__card-image"
            @click.prevent
            @click="setupModal(project, event)"
          />
        </a>
      </div>
      <div class="work__text-box">
        <h2 class="work__card-title">
          {{ project.name }}
        </h2>
        <p class="work__card-description">{{ project.description }}</p>
        <div class="work__technologies-container">
          <h3 class="work__tech-description">Made with:</h3>
          <div class="work__tech-icons">
            <img
              v-for="icon in project.icons"
              :key="icon.index"
              class="work__tech-icon"
              v-bind:src="require('@/assets/svg-icons/' + icon)"
              :alt="icon.slice(0, -4)"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // data() {
  //   return {

  //   };
  // },
  props: {
    projects: {
      type: Object,
      required: true,
    },
  },
  name: "Card",

  methods: {
    setupModal(project) {
      const posY = event.pageY;
      this.$store.state.modalY = posY;
      this.$store.state.currentModalData = project;
      this.$emit("show-story", project);
      // add spinner to the background modal image for sometime
      // I could use a settimout function for this
    },
  },
};
</script>

<style lang="scss" scoped>
.work {
  margin-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 60px;
  row-gap: 70px;

  &__card-container {
    display: flex;
    flex-direction: column;
    width: 445px;
    // height: 400px;
    box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.16);
    margin-bottom: 3rem;

    // border-radius: 55px;
  }

  &__card-image-container {
    position: relative;
    // border: 1px solid #fff;
    // border-radius: 5px 5px 0 0;
    // &::after {
    //     content: 'view case study';
    //         width: 99%;
    //   height: 99%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: rgba(0,0,0,0.7);
    //   z-index: 1000;
    //   font-size: 3rem;
    //   -webkit-transition: all 0.4s ease-in-out 0s;
    // -moz-transition: all 0.4s ease-in-out 0s;
    // transition: all 0.4s ease-in-out 0s;
    //   }
    transition: all 0.25s ease;
    &:hover {
      transform: scale(1.01);
    }
  }
  &__card-image {
    // border: 1px solid #fff;
    z-index: 200;
    border-radius: 5px 5px 0 0;
    width: 100%;
    // height: 100%;
    border-bottom: 0.5px solid #20464d12;

    transition: all 0.25s ease;
    &:hover {
      cursor: pointer;
      filter: brightness(30%);
    }
  }
  // &__card-link {
  //   z-index:
  // }
  &__card-link:hover &__card-link-text {
    font-weight: bold;
    z-index: 100;
    font-size: 3rem;
  }

  &__card-link-text {
    position: absolute;
    z-index: -100;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  &__text-box {
    color: #0a1f24;
    padding: 1rem 1rem 0.5rem 1rem;
    //   border: 1px solid #707070;
    border-radius: 0 0 5px 5px;
    width: 100%;
  }
  &__card-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  &__card-description {
    font-size: 2.3rem;
    line-height: 1.3;
    margin-bottom: 2.5rem;
  }
  &__technologies-container {
    display: flex;
    justify-content: space-between;
  }
  &__tech-description {
    font-weight: 400;
  }
  &__tech-icons > * {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
  &__tech-icon {
    width: 33px;
    height: 32px;
  }
}

// .work__card-link:hover .work__card-link-text {
//   z-index: 100000 !important;
// }
// .work-card-link:hover work-card-link-text {
//   z-index: 100000 !important;
// }
</style>
