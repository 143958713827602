<template>
  <div class="modal hide">
    <div class="close-icon-container" @click.prevent @click="closeModal">
      <svg
        id="close-icon"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="46.007"
        height="46.007"
        viewBox="0 0 46.007 46.007"
      >
        <defs>
          <linearGradient
            id="a"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#6b3f88" />
            <stop offset="0.305" stop-color="#306c85" />
            <stop offset="0.64" stop-color="#001959" />
            <stop offset="1" stop-color="#30989c" />
          </linearGradient>
        </defs>
        <path
          d="M53.507,12.134,48.874,7.5,30.5,25.87,12.134,7.5,7.5,12.134,25.87,30.5,7.5,48.874l4.634,4.634L30.5,35.137l18.37,18.37,4.634-4.634L35.137,30.5Z"
          transform="translate(-7.5 -7.5)"
          fill="url(#a)"
        />
      </svg>
    </div>
    <h2 class="modal__title">
      {{ this.$store.state.currentModalData.title }}
    </h2>
    <div class="modal__img-container">
      <!-- <Spinner /> -->
      <img
        :src="
          require('@/assets/images/card-images/modal/' +
            this.$store.state.currentModalData.modalImage)
        "
        alt=""
        class="modal__img"
      />
    </div>
    <div class="modal__main-content">
      <h3 class="modal__h3">Technologies used</h3>
      <hr class="hr" />
      <div class="modal__technologies-container">
        <div
          v-for="technology in this.$store.state.currentModalData.technologies"
          :key="technology.index"
          class="modal__technologies"
        >
          <h4 class="modal__h4">{{ Object.keys(technology)[0] }}</h4>
          <p class="paragraph">
            {{ technology[Object.keys(technology)[0]] }}
          </p>
        </div>
      </div>
      <hr class="hr" />

      <section class="modal__goal">
        <h4 class="modal__h4">Goal</h4>
        <p class="paragraph">{{ this.$store.state.currentModalData.goal }}</p>
        <hr class="hr" />
      </section>

      <section
        v-for="section in this.$store.state.currentModalData.stories"
        :key="section.index"
        class="modal__section"
      >
        <h4 class="modal__h4">{{ section.title }}</h4>
        <p class="paragraph">{{ section.body }}</p>
        <img
          v-if="section.image"
          :src="require('@/assets/images/card-images/modal/' + section.image)"
          alt=""
          class="modal__story-img"
        />
        <img
          v-if="section.image2"
          :src="require('@/assets/images/card-images/modal/' + section.image2)"
          alt=""
          class="modal__story-img"
        />
        <hr class="hr" />
      </section>

      <section
        v-if="this.$store.state.currentModalData.url"
        class="modal__url-container"
      >
        <a
          :href="this.$store.state.currentModalData.url"
          class="modal__url-link"
          target="_blank"
          rel="noreferrer"
          >{{ this.$store.state.currentModalData.website }}</a
        >
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  props: {},
  name: "PortfolioModal",

  methods: {
    closeModal() {
      let modal = document.querySelector(".modal");
      modal.scrollTop = 0;
      modal.classList.add("hide");
      document.querySelector(".work").classList.remove("blurred");
      document.body.style.position = "";
      document.body.classList.remove("overflowHidden");
      // Here we can add the new image
      // this.$store.state.currentModalData.modalImage = "placeholder.jpg";
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon-container {
  position: absolute;
  top: 35px;
  right: 35px;
  position: sticky;
  display: inline-flex;

  margin-left: 95%;

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1562px) {
    margin-left: 93.5%;
  }
  @media screen and (max-width: 1067px) {
    top: 15px;
    right: 29px;
  }
  @media screen and (max-width: 752px) {
    margin-left: 92.5%;
  }
  @media screen and (max-width: 662px) {
    margin-left: 89.5%;
    top: 7px;
  }
  @media screen and (max-width: 551px) {
    margin-left: 87.5%;
    top: 7px;
  }
  @media screen and (max-width: 423px) {
    margin-left: 84%;
    top: 7px;
  }
  @media screen and (max-width: 333px) {
    margin-left: 79%;
    top: 7px;
  }
}
#close-icon {
  @media screen and (max-width: 1067px) {
    width: 32px;
  }
  @media screen and (max-width: 662px) {
    box-sizing: content-box;
    padding: 1rem;
  }
  @media screen and (max-width: 467px) {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);
  }
}
.modal {
  border-radius: 5px;
  color: #3f3d56;
  text-align: center;
  position: absolute;
  overflow-y: auto;
  top: 0.5%;
  left: 2.75%;
  min-height: 45vh;
  max-height: calc(100vh - 40px);
  width: 95vw;
  background-color: #fff;
  z-index: 2000;
  transition: opacity 0.5s linear;
  box-shadow: 0 14px 88px rgba(0, 0, 0, 0.85), 0 10px 10px rgba(0, 0, 0, 0.22);

  @media screen and (max-width: 1047px) {
    // top: 265px !important;
    left: 1.55%;
    width: 97vw;
  }
  @media screen and (max-width: 662px) {
    text-align: left;
  }
  @media screen and (max-width: 551px) {
    left: 0.5%;
    width: 99vw;
  }

  &__title {
    font-size: 6rem;
    margin: 6rem 0;
    @media screen and (max-width: 1562px) {
      font-size: 5rem;
      margin-top: 0;
    }
    @media screen and (max-width: 1256px) {
      font-size: 4.25rem;
    }
    @media screen and (max-width: 840px) {
      font-size: 3.75rem;
    }
    @media screen and (max-width: 752px) {
      font-size: 3.25rem;
    }
    @media screen and (max-width: 662px) {
      margin-left: 1.5rem;
      font-size: 2.75rem;
      margin-top: -5rem;
      margin-bottom: 2.5rem;
    }
    @media screen and (max-width: 551px) {
      margin-top: 0rem;
    }
  }

  &__h3,
  &__h4 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 3rem;
    font-weight: 400;
    text-shadow: 0.5px 0 0 currentColor;
    @media screen and (max-width: 662px) {
      font-size: 2.7rem;
    }
  }

  &__technologies-container {
    display: flex;
    gap: 50px;
    margin-top: 0.3rem;
    @media screen and (max-width: 383px) {
      gap: 25px;
    }
  }

  &__goal {
    margin-top: 5rem;
  }

  &__section {
    margin-top: 7.5rem;

    &:last-child {
      margin-bottom: 5rem;
    }
  }

  &__img {
    box-shadow: -3px 5px 10px rgba(0, 0, 0, 0.16);
    width: 82%;

    @media screen and (max-width: 662px) {
      margin-left: 1.75rem;
    }
    @media screen and (max-width: 467px) {
      width: 94%;
      margin-left: 1rem;
    }
  }

  &__main-content {
    max-width: 931px;
    text-align: left;
    margin: 0 auto;
    margin-top: 6rem;
    @media screen and (max-width: 1256px) {
      max-width: 772px;
    }
    @media screen and (max-width: 992px) {
      max-width: 619px;
    }
    @media screen and (max-width: 798px) {
      max-width: 488px;
    }
    @media screen and (max-width: 662px) {
      margin-left: 1.75rem;
      max-width: 410px;
    }
    @media screen and (max-width: 467px) {
      margin: 2rem 1rem 0 1rem;
    }
  }

  &__story-img {
    width: 100%;
    box-shadow: 0px 0px 4px #0000001f;
    margin-top: 0.4rem;
  }

  &__url-container {
    margin: 5rem 0;
    text-align: center;
    border-radius: 5px;
  }
  &__url-link {
    font-size: 3rem;
    padding: 2rem;

    // &:hover {
    //   transition: all 2s ease;
    //   background-image: linear-gradient(
    //     to bottom right,
    //     rgb(107, 63, 136),
    //     rgb(48, 108, 133) 45%,
    //     rgb(0, 25, 89) 70%,
    //     rgb(107, 63, 136)
    //   );
    //   color: #fff;
    // }
  }
}
</style>
